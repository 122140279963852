var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", {
        staticClass: "sr-only",
        attrs: { "aria-live": "assertive" },
        domProps: { textContent: _vm._s(_vm.liveRegionText) },
      }),
      _c("div", { ref: "errorContainer" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              attrs: { cols: _vm.isTimeEntryDrawerExpanded ? 6 : 12 },
            },
            [
              _vm.timesheetDetailFeatures.percentageOrDuration.data
                .isPercentageAgency
                ? _c(
                    "div",
                    [
                      _vm.timesheetDetailFeatures.percentageOrDuration.data
                        .isPercentageAgency
                        ? _c("v-label", { attrs: { for: "durationInput" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryPercentLabel"
                                )
                              ) + " "
                            ),
                            _c("span", { staticClass: "required" }, [
                              _vm._v("*"),
                            ]),
                          ])
                        : _c("v-label", { attrs: { for: "durationInput" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryDuration"
                                )
                              ) + " "
                            ),
                            _c("span", { staticClass: "required" }, [
                              _vm._v("*"),
                            ]),
                          ]),
                      _c("validation-provider", {
                        attrs: {
                          rules: "required|durationValidation",
                          name: "DurationField",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("v-text-field", {
                                    ref: "timeEntryInput",
                                    attrs: {
                                      id: "durationInput",
                                      outlined: "",
                                      dense: "",
                                      autofocus: "",
                                      placeholder: "Duration",
                                      "error-messages": errors,
                                      "aria-describedby": "durationInputError",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.handleDurationFocus(errors)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "message",
                                          fn: function ({ message }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  attrs: {
                                                    "aria-live": "polite",
                                                    id: "durationInputError",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(message) + " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value:
                                        _vm.timesheetDetail
                                          .durationPercentageValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.timesheetDetail,
                                          "durationPercentageValue",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "timesheetDetail.durationPercentageValue",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1384680250
                        ),
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("v-label", { attrs: { for: "durationInput" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.EntryFormLabels.TimesheetEntryDuration"
                            )
                          ) + " "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      ]),
                      _c("validation-provider", {
                        attrs: {
                          rules: "required|durationValidation",
                          name: "DurationField",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("v-text-field", {
                                  ref: "timeEntryInput",
                                  attrs: {
                                    id: "durationInput",
                                    outlined: "",
                                    "aria-required": "true",
                                    dense: "",
                                    autofocus: "",
                                    placeholder: "Duration",
                                    "error-messages": errors,
                                    "aria-describedby": "durationInputError",
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.handleDurationFocus(errors)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "message",
                                        fn: function ({ message }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                attrs: {
                                                  "aria-live": "polite",
                                                  id: "durationInputError",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(message) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.timesheetDetail.durationValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.timesheetDetail,
                                        "durationValue",
                                        $$v
                                      )
                                    },
                                    expression: "timesheetDetail.durationValue",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
              !_vm.isHideTimesheetWorkcode
                ? _c(
                    "div",
                    {
                      staticClass: "attached-dropdown",
                      attrs: { id: "workCodeInput-container" },
                    },
                    [
                      _c("v-label", { attrs: { for: "workCodeInput" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.EntryFormLabels.TimesheetEntryLabelWorkcode"
                            )
                          ) + " "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      ]),
                      _vm.selectedPPMTask.jobTitleForBilling ||
                      _vm.timesheetDetail.disablePPMSelect ||
                      (_vm.agencyData.isRigidAgency &&
                        _vm.timesheetDetailFeatures.workcode.data.workCodes
                          .length === 1)
                        ? _c("v-text-field", {
                            attrs: {
                              id: "workCodeInput",
                              value: _vm.computedWorkCodeText,
                              outlined: "",
                              dense: "",
                              disabled: "",
                              "aria-describedby": "workCodeInputError",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "message",
                                  fn: function ({ message }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          attrs: {
                                            "aria-live": "polite",
                                            id: "workCodeInputError",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(message) + " ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2176080259
                            ),
                          })
                        : _c("validation-provider", {
                            attrs: {
                              rules: "required",
                              name: "Workcode",
                              immediate: !!Object.keys(
                                this.isInlineErrorResolved
                              ).length,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("v-autocomplete", {
                                        ref: "workCodeInput",
                                        attrs: {
                                          role: "listbox",
                                          id: "workCodeInput",
                                          attach: "#workCodeInput-container",
                                          "menu-props": {
                                            nudgeBottom: 28,
                                            offsetY: true,
                                            allowOverflow: true,
                                          },
                                          outlined: "",
                                          dense: "",
                                          items:
                                            _vm.timesheetDetailFeatures.workcode
                                              .data.workCodes,
                                          "error-messages": errors,
                                          "item-text": "label",
                                          "item-value": "value",
                                          placeholder: "Workcode",
                                          disabled: _vm.disableWorkCode,
                                          clearable: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.loadBillableIndicators(
                                              _vm.timesheetDetail.workCode
                                            )
                                          },
                                          keydown: _vm.arrowKeyHandler,
                                          focus: function ($event) {
                                            return _vm.announceError(errors)
                                          },
                                        },
                                        model: {
                                          value: _vm.timesheetDetail.workCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.timesheetDetail,
                                              "workCode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "timesheetDetail.workCode",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4124463143
                            ),
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "v-col",
            {
              class: ["pb-0", { "pt-0": !_vm.isTimeEntryDrawerExpanded }],
              attrs: { cols: _vm.isTimeEntryDrawerExpanded ? 6 : 12 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "attached-dropdown",
                  attrs: { id: "billableIndicator-container" },
                },
                [
                  _c("v-label", { attrs: { for: "billableIndicator" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "timesheetUI.EntryFormLabels.TimesheetEntryLabelBI"
                        )
                      ) + " "
                    ),
                    _c("span", { staticClass: "required" }, [_vm._v("*")]),
                  ]),
                  _c("validation-provider", {
                    attrs: { rules: "required", name: "Billable Indicator" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("v-combobox", {
                              ref: "billableIndicator",
                              attrs: {
                                role: "listbox",
                                id: "billableIndicator",
                                attach: "#billableIndicator-container",
                                "menu-props": {
                                  offsetY: true,
                                  nudgeBottom: 28,
                                },
                                "aria-required": "true",
                                outlined: "",
                                dense: "",
                                items: _vm.timesheetDetailBillableIndicators,
                                "item-text": "label",
                                placeholder: "Billable Indicator",
                                "error-messages": errors,
                                loading: _vm.loadingBI,
                                "return-object": "",
                                disabled:
                                  _vm.isTimesheetApproved ||
                                  _vm.timesheetDetailBillableIndicators
                                    .length <= 1 ||
                                  _vm.timesheetDetail.disablePPMSelect ||
                                  _vm.disablePPMWorkcode,
                              },
                              on: {
                                keydown: _vm.arrowKeyHandler,
                                focus: function ($event) {
                                  return _vm.announceError(errors)
                                },
                              },
                              model: {
                                value: _vm.timesheetDetail.billableIndicator,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.timesheetDetail,
                                    "billableIndicator",
                                    $$v
                                  )
                                },
                                expression: "timesheetDetail.billableIndicator",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("div", [_c("CommentField")], 1),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }