var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-label", { attrs: { for: "commentInput" } }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelComments"
            )
          ) + " "
        ),
      ]),
      _c("validation-provider", {
        attrs: { rules: "maxComment", name: "Comment" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ errors }) {
              return [
                _c("v-text-field", {
                  attrs: {
                    id: "commentInput",
                    outlined: "",
                    dense: "",
                    placeholder: _vm.$t(
                      "timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelCommentsPlaceholder"
                    ),
                    "error-messages": errors,
                    counter: "",
                    maxlength: "125",
                    "aria-describedby": "commentInputError",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.setHasTimeEntryChanged({ e: true, data: null })
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "message",
                        fn: function ({ message }) {
                          return [
                            _c(
                              "div",
                              {
                                attrs: {
                                  "aria-live": "polite",
                                  id: "commentInputError",
                                },
                              },
                              [_vm._v(" " + _vm._s(message) + " ")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.comment,
                    callback: function ($$v) {
                      _vm.comment = $$v
                    },
                    expression: "comment",
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }